<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 align-middle max-w-5xl w-full"
      >
        <div class="flex flex-col justify-center items-center">
          <div class="bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("accounts.temp_speed.title") }}
            </div>

            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="flex flex-col w-full md:w-1/3 items-center justify-center bg-gray-300"
          >
            <div class="text-2xl mt-6">
              {{ $t("accounts.username") }}
            </div>
            <div class="w-full">
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div class="rounded-r-full text-center p-1 inline-block w-full">
                  {{ selectedAccount.username }}
                </div>
              </div>
            </div>
            <div class="text-2xl mt-6">
              {{ $t("accounts.temp_speed.default_speed") }}
            </div>
            <div class="w-full">
              <div class="font-bold">
                {{ $t("profiles.download_speed") }}
              </div>
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div class="rounded-r-full text-center p-1 inline-block w-full">
                  {{ formatSpeed(selectedAccount.profile.download_rate_kb) }}
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="font-bold">
                {{ $t("profiles.upload_speed") }}
              </div>
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div class="rounded-r-full text-center p-1 inline-block w-full">
                  {{ formatSpeed(selectedAccount.profile.upload_rate_kb) }}
                </div>
              </div>
            </div>
            <div class="text-2xl mt-6">
              {{ $t("accounts.temp_speed.new_speed") }}
            </div>
            <div class="w-full">
              <div class="font-bold">
                {{ $t("profiles.download_speed") }}
              </div>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.download }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      temp_speed.download_rate_kb =
                        temp_speed.download_rate_kb + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      temp_speed.download_rate_kb =
                        temp_speed.download_rate_kb > 0
                          ? temp_speed.download_rate_kb - 1
                          : (temp_speed.download_rate_kb = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="temp_speed.download_rate_kb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
                <select
                  v-model="temp_speed.download_rate_limit_factor"
                  class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                >
                  <option
                    v-for="unit in [
                      { name: 'Kb/s', id: 1 },
                      { name: 'Mb/s', id: 1024 },
                    ]"
                    v-bind:key="unit.id"
                    :value="unit.id"
                  >
                    {{ unit.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="w-full">
              <div class="font-bold">
                {{ $t("profiles.upload_speed") }}
              </div>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.upload }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      temp_speed.upload_rate_kb = temp_speed.upload_rate_kb + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      temp_speed.upload_rate_kb =
                        temp_speed.upload_rate_kb > 0
                          ? temp_speed.upload_rate_kb - 1
                          : (temp_speed.upload_rate_kb = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="temp_speed.upload_rate_kb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
                <select
                  v-model="temp_speed.download_rate_limit_factor"
                  class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                >
                  <option
                    v-for="unit in [
                      { name: 'Kb/s', id: 1 },
                      { name: 'Mb/s', id: 1024 },
                    ]"
                    v-bind:key="unit.id"
                    :value="unit.id"
                  >
                    {{ unit.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="w-full">
              <div class="font-bold">
                {{ $t("accounts.temp_speed.session_time") }}
              </div>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.session_seconds }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      temp_speed.session_seconds =
                        temp_speed.session_seconds + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      temp_speed.session_seconds =
                        temp_speed.session_seconds > 0
                          ? temp_speed.session_seconds - 1
                          : (temp_speed.session_seconds = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="temp_speed.session_seconds"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div class="mt-6">
              {{ $t("accounts.temp_speed.note") }}
            </div>
            <button
              @click.prevent="store"
              class="bg-green-500 text-white rounded-full w-full py-1 px-3 my-6 mt-6"
              :class="{ 'bg-opacity-50 cursor-default': !validated }"
              :disabled="!validated || loading"
            >
              {{ $t("accounts.temp_speed.apply") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle"
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
// import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
import ExpiredWindow from "../ExpiredWindow.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
import VueDatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: {
    NumericalSpinner,
    ExpiredWindow,
    ToggleButton,
    VueDatepicker,
  },
  name: "TempSpeed",
  props: ["visible", "selectedAccount"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      renew_amount: 1,
      profiles: null,
      validated: false,
      loading: false,
      temp_speed: {
        upload_rate_kb: 1,
        download_rate_kb: 1,
        download_rate_limit_factor: 1024,
        session_seconds: 60,
      },
      errors: {
        upload: null,
        download: null,
      },
    };
  },
  async created() {},
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    async store() {
      this.loading = true;
      this.temp_speed.download_rate_kb =
        this.temp_speed.download_rate_kb *
        this.temp_speed.download_rate_limit_factor;
      this.temp_speed.upload_rate_kb =
        this.temp_speed.upload_rate_kb *
        this.temp_speed.download_rate_limit_factor;
      this.temp_speed.session_seconds = this.temp_speed.session_seconds * 60;
      const response = await axios.post(
        `accounts/${this.selectedAccount.id}/speed/temp`,
        this.temp_speed
      );
      if (response.status == 200) {
        this.$router.push({
          path: "/accounts",
          query: { success: "success" },
        });
        this.$emit("updated");
        this.loading = false;
      }
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    formatSpeed(speed) {
      if (speed < 0) return "∞";
      if (speed > 1023) return speed / 1024 + " Mb/s";
      else return speed + " Kb/s";
    },
    validate() {
      this.validated = true;
      this.errors.download = null;
      this.errors.upload = null;
      if (this.temp_speed.download_rate_kb <= 0) {
        this.errors.download = true;
        this.validated = false;
      }
      if (this.temp_speed.upload_rate_kb <= 0) {
        this.errors.upload = true;
        this.validated = false;
      }
    },
  },
};
</script>
