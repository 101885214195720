<template>
  <div class="main-content flex-1 bg-gray-100">
    <loading-overlay :loading="loading_overlay" />
    <BillPay
      v-if="showPayBill && hasPrivilege('accounting_pay_bill')"
      :visible="showPayBill"
      :selectedBill="selectedBill"
      @hide-details="showPayBill = false"
      @updated="
        showPayBill = false;
        applyFilters();
      "
    />
    <transition name="fade">
      <WarnningConfirm
        :message="$t('messages.bills.delete')"
        :visible="showDeleteBill"
        @confirmed="deleteBill()"
        @hide="showDeleteBill = false"
      />
    </transition>
    <transition name="fade">
      <WarnningConfirm
        v-if="showNotificationConfirm"
        :message="notificationMessage"
        :visible="showNotificationConfirm"
        @confirmed="sendNotification"
        @hide="
          showNotificationConfirm = false;
          selectedBill = null;
        "
      />
    </transition>
    <div class="p-2 md:p-6">
      <div class="flex justify-between w-full">
        <div class="flex">
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>

        <div
          v-if="settings.center == 1"
          class="rounded-full bg-white flex justify-center items-center shadow"
        >
          <button
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-4 font-bold flex justify-center items-center"
            @click="print_element('billprint')"
            :class="{
              'opacity-50 disabled': !reportReady,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-9 w-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
            <div>التقرير الشهري</div>
          </button>
          <input
            type="text"
            class="rounded-full h-full text-center focus:outline-none"
            placeholder="2023-01-01 بداية"
            v-model="reportDateStart"
            @input="isValidDate"
          />
          <div class="h-full border-black border-l"></div>
          <input
            type="text"
            class="rounded-full h-full text-center focus:outline-none"
            placeholder="2023-01-30 نهاية"
            v-model="reportDateEnd"
            @input="isValidDate"
          />
          <img
            v-if="report_loading"
            class="inline-block h-12 w-12"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
        <button
          class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-4 font-bold mr-4"
          @click="print_element('printContent')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-9 w-9"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
            />
          </svg>
        </button>
      </div>
      <Vue3SimpleHtml2pdf
        ref="vue3SimpleHtml2pdf"
        :options="pdfOptions"
        :filename="exportFilename"
        id="printContent"
      >
        <div class="w-full flex flex-col">
          <div
            class="w-full flex flex-col text-center overflow-auto overflow-x-scroll scroll"
          >
            <table>
              <thead class="border-b-2 border-black text-xs md:text-base">
                <th>
                  <div class="inline-block">{{ $t("transactions.id") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.id"
                  />
                </th>
                <th>
                  <div>{{ $t("bills.slug") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.slug"
                  />
                </th>
                <th>
                  <div>{{ $t("transactions.username") }}</div>
                  <select
                    v-if="hasPrivilege('accounting_search_bills')"
                    @change="applyFilters"
                    v-model="filters.user_id"
                    class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-2/3 noprint"
                  >
                    <option value="-1">{{ $t("control.all") }}</option>
                    <option
                      v-for="ft in admins"
                      v-bind:key="ft.id"
                      :value="ft.id"
                      :class="{
                        'text-green-400 text-center': ft.type == 'reseller',
                      }"
                    >
                      {{ ft.email }}
                    </option>
                  </select>
                </th>
                <th>
                  <div>{{ $t("accounts.first_name") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.account_name"
                  />
                </th>
                <th>
                  <div>{{ $t("accounts.account") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.account_username"
                  />
                </th>

                <th>
                  <div>{{ $t("profiles.profile") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.profile_name"
                  />
                </th>
                <th>
                  <div>{{ $t("bills.price") }}</div>
                </th>
                <th>
                  <div>{{ $t("bills.paid") }}</div>
                  <select
                    v-if="hasPrivilege('accounting_search_bills')"
                    @change="applyFilters"
                    v-model="filters.paid"
                    class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none text-center noprint"
                  >
                    <option
                      v-for="ft in [
                        { id: '', name: $t('control.all') },
                        { id: '1', name: $t('control.yes') },
                        { id: '0', name: $t('control.no') },
                      ]"
                      v-bind:key="ft.id"
                      :value="ft.id"
                    >
                      {{ ft.name }}
                    </option>
                  </select>
                </th>
                <th>
                  <div>{{ $t("bills.from") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.from"
                  />
                </th>
                <th>
                  <div>{{ $t("bills.to") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.to"
                  />
                </th>
                <th>
                  <div>{{ $t("bills.notes") }}</div>
                  <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.notes"
                  />
                </th>
                <th>
                  <div>{{ $t("bills.created_at") }}</div>
                  <!-- <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.created_at"
                  /> -->
                  <VueDatepicker
                    v-if="hasPrivilege('accounting_search_bills')"
                    v-model="filters.created_at"
                    @update:model-value="applyFilters"
                    :locale="$i18n.locale"
                    :format="format"
                    :teleport="true"
                    range
                  />
                </th>
                <th>
                  <div>{{ $t("control.updated_at") }}</div>
                  <!-- <input
                    v-if="hasPrivilege('accounting_search_bills')"
                    type="text"
                    class="bg-white rounded-full w-2/3 shadow-lg p-1 focus:outline-none text-center noprint"
                    :placeholder="$t('control.filter')"
                    @input="applyFilters"
                    v-model="filters.updated_at"
                  /> -->
                  <VueDatepicker
                    v-if="hasPrivilege('accounting_search_bills')"
                    v-model="filters.updated_at"
                    @update:model-value="applyFilters"
                    :locale="$i18n.locale"
                    :format="format"
                    :teleport="true"
                    range
                  />
                </th>
                <th class="noprint"></th>
              </thead>
              <tbody>
                <tr
                  v-for="(bill, index) in dataList"
                  v-bind:key="bill.id"
                  class="text-xs md:text-base"
                  :class="{
                    'bg-gray-200': index % 2 == 0,
                  }"
                >
                  <td>{{ bill.id }}</td>
                  <td>{{ bill.slug }}</td>
                  <td>{{ bill.user.email }}</td>
                  <td v-if="bill.account">
                    {{
                      (bill.account.first_name ?? "") +
                        " " +
                        (bill.account.last_name ?? "")
                    }}
                  </td>
                  <td v-else>DELETED</td>
                  <td>
                    <div
                      v-if="bill.account"
                      class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold inline-block"
                      @click.prevent="
                        this.$store.commit('changeKey');
                        this.$router.push(
                          `/accounts?useranme=${bill.account.username}`
                        );
                      "
                    >
                      {{ bill.account.username }}
                    </div>
                  </td>
                  <td>
                    {{ bill.profile ? bill.profile.name : "" }}
                  </td>
                  <td class="text-xl">
                    <div>
                      {{ bill.price
                      }}<span class="font-sans font-bold">{{
                        this.$store.getters.settings.currency
                      }}</span>
                    </div>
                  </td>
                  <td class="text-xl">
                    <div
                      :class="{
                        'rounded-full px-2 py-1 bg-red-600 text-red-200 cursor-pointer':
                          bill.price > bill.paid,
                      }"
                      @click="
                        selectedBill = bill;
                        showPayBill = true;
                      "
                    >
                      {{ bill.paid
                      }}<span class="font-sans font-bold">{{
                        this.$store.getters.settings.currency
                      }}</span>
                    </div>
                  </td>
                  <td>{{ formatDate(bill.from) }}</td>
                  <td>{{ formatDate(bill.to) }}</td>
                  <td>{{ bill.notes }}</td>
                  <td>{{ formatDate(bill.created_at) }}</td>
                  <td>{{ formatDate(bill.updated_at) }}</td>
                  <td class="noprint">
                    <div
                      v-if="bill.print"
                      class="px-3 p-1 mx-2 bg-ssr-side_bar-settings text-white text-center rounded-full inline-block"
                      :class="{
                        disabled: !this.$store.getters.settings.license.active,
                      }"
                    >
                      <button @click="print_element('billPrint')">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      v-else
                      class="px-3 p-1 mx-2 bg-blue-500 text-white text-center rounded-full inline-block cursor-pointer"
                      @click.prevent="
                        selectedBill = bill;
                        bill.print = true;
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        />
                      </svg>
                    </div>
                    <div
                      v-if="
                        bill.price > bill.paid &&
                          hasPrivilege('accounting_pay_bill')
                      "
                      @click="
                        selectedBill = bill;
                        showPayBill = true;
                      "
                      class="px-3 p-1 mx-2 bg-green-500 text-white text-center rounded-full inline-block cursor-pointer"
                      :class="{
                        disabled: !this.$store.getters.settings.license.active,
                      }"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      v-if="
                        bill.price > bill.paid &&
                          hasPrivilege('notifications_push_notifications')
                      "
                      class="px-3 p-1 mx-2 bg-yellow-400 text-white text-center rounded-full inline-block cursor-pointer"
                      :class="{
                        disabled: !this.$store.getters.settings.license.active,
                      }"
                      @click="
                        selectedBill = bill;
                        showBillNotification(bill);
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                        />
                      </svg>
                    </div>
                    <div
                      v-if="bill.today && hasPrivilege('accounting_pay_bill')"
                      @click="
                        selectedBill = bill;
                        showDeleteBill = true;
                      "
                      class="px-3 p-1 mx-2 bg-red-500 text-white text-center rounded-full inline-block cursor-pointer"
                      :class="{
                        disabled: !this.$store.getters.settings.license.active,
                      }"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="loading" class="w-full flex justify-center">
            <img
              class="inline-block h-12 w-12"
              src="@/assets/loading.gif"
              alt="Loading"
            />
          </div>

          <div
            class="flex text-sm md:text-xl justify-around bg-ssr-side_bar-button text-white font-sans"
            :class="{ rtl: this.$i18n.locale == 'ar' }"
          >
            <div class="flex">
              <p>{{ $t("bills.total.current_grand") }}</p>
              <p class="mx-1">{{ current_bills }}</p>
              <span>{{ settings.currency }}</span>
            </div>
            <div class="flex">
              <p>{{ $t("bills.total.current_paid") }}</p>
              <p class="mx-1">{{ current_paid }}</p>
              <span>{{ settings.currency }}</span>
            </div>
            <div
              class="flex"
              :class="{
                'text-green-400': total_bills - total_paid <= 0,
                'text-red-400': total_bills - total_paid > 0,
              }"
            >
              <p>{{ $t("bills.total.current_debt") }}</p>
              <p class="mx-1">{{ current_bills - current_paid }}</p>
              <span>{{ settings.currency }}</span>
            </div>
          </div>
          <div
            class="mt-1 flex justify-around bg-ssr-side_bar-button text-white text-sm md:text-xl font-sans font-bold"
            :class="{ rtl: this.$i18n.locale == 'ar' }"
          >
            <div class="flex">
              <p>{{ $t("bills.total.total_grand") }}</p>
              <p class="mx-1">{{ total_bills }}</p>
              <span>{{ settings.currency }}</span>
            </div>
            <div class="flex">
              <p>{{ $t("bills.total.total_paid") }}</p>
              <p class="mx-1">{{ total_paid }}</p>
              <span>{{ settings.currency }}</span>
            </div>
            <div
              class="flex"
              :class="{
                'text-green-400': total_bills - total_paid <= 0,
                'text-red-400': total_bills - total_paid > 0,
              }"
            >
              <p>{{ $t("bills.total.total_debt") }}</p>
              <p class="mx-1">
                {{ total_bills - total_paid }}
              </p>
              <span>{{ settings.currency }}</span>
            </div>
          </div>
          <pagination-row
            class="mt-2"
            @triggerupdate="fetchDataPortion"
            :meta="meta"
            :row="filters.rows"
          />
        </div>
      </Vue3SimpleHtml2pdf>
      <div v-if="selectedBill" id="billPrint">
        <div
          :style="{ direction: $i18n.locale == 'ar' ? 'rtl' : 'ltr' }"
          class="grid grid-cols-8 justify-center w-full p-6 noScreen"
        >
          <div class="col-span-2 flex flex-col justify-center items-center">
            <img :src="server_url + settings.network_logo" class="h-32" />
            <div class="text-2xl">{{ settings.network_name }}</div>
          </div>
          <div class="col-span-8 h-12"></div>
          <div class="col-span-2 flex flex-col items-start justify-start">
            <div class="font-bold">{{ $t("bills.invoice.bill_to") }}:</div>
            <div>
              {{
                selectedBill.account.first_name +
                  " " +
                  selectedBill.account.last_name
              }}
            </div>
            <div>
              {{ selectedBill.account.address }}
            </div>
            <div style="direction: ltr;">
              {{ selectedBill.account.phone }}
            </div>
            <div>
              {{ selectedBill.account.phone_line }}
            </div>
          </div>
          <div
            class="col-span-3 col-start-6 flex flex-col items-start justify-start"
          >
            <div class="w-full flex justify-between">
              <div>{{ $t("bills.invoice.issued_date") }}:</div>
              <div>{{ formatDate(selectedBill.created_at) }}</div>
            </div>
            <div class="w-full flex justify-between">
              <div>{{ $t("bills.invoice.account_id") }}:</div>
              <div>{{ selectedBill.account.id }}</div>
            </div>
            <div class="w-full flex justify-between">
              <div>{{ $t("bills.invoice.bill_id") }}:</div>
              <div>{{ selectedBill.id }}</div>
            </div>
            <div class="w-full flex justify-between">
              <div>{{ selectedBill.slug }}</div>
            </div>
          </div>
          <div class="col-span-8 h-6"></div>
          <div
            class="flex flex-col col-span-2 bg-yellow-300 text-black justify-center text-xl border-gray-100 border p-4"
          >
            <div>{{ $t("bills.invoice.profile") }}:</div>
            <div class="font-bold">{{ selectedBill.profile.name }}</div>
          </div>
          <div
            class="flex flex-col col-span-2 bg-yellow-300 text-black justify-center text-xl border-gray-100 border p-4"
          >
            <div>{{ $t("bills.from") }}:</div>
            <div class="font-bold">{{ formatDate(selectedBill.from) }}</div>
          </div>
          <div
            class="flex flex-col col-span-2 bg-yellow-300 text-black justify-center text-xl border-gray-100 border p-4"
          >
            <div>{{ $t("bills.to") }}:</div>
            <div class="font-bold">{{ formatDate(selectedBill.to) }}</div>
          </div>
          <div
            class="flex flex-col col-span-2 bg-gray-800 text-white justify-center text-xl border-gray-100 border p-4"
          >
            <div>{{ $t("bills.price") }}:</div>
            <div class="font-bold font-sans">
              {{ selectedBill.price + settings.currency }}
            </div>
          </div>
          <div class="col-span-8 h-6"></div>

          <div class="col-span-8" style="text-align: end;">
            <table class="w-full">
              <thead class="font-bold border-b-2 border-black">
                <th colspan="2" class="px-2">
                  {{ $t("bills.invoice.description") }}
                </th>
                <th>{{ $t("bills.invoice.amount") }}</th>
              </thead>
              <tbody>
                <tr class="border-black border-t border-b">
                  <td colspan="2" class="px-2">
                    {{ $t("bills.price") }}
                  </td>
                  <td>
                    {{ selectedBill.price }}
                    <span class="font-sans font-bold">{{
                      settings.currency
                    }}</span>
                  </td>
                </tr>
                <tr class="border-black border-t border-b">
                  <td colspan="2" class="px-2">
                    {{ $t("bills.paid") }}
                  </td>
                  <td>
                    {{ selectedBill.paid }}
                    <span class="font-sans font-bold">{{
                      settings.currency
                    }}</span>
                  </td>
                </tr>
              </tbody>
              <tfoot class=" border-t-2 border-black">
                <th colspan="2" class="px-2">
                  {{ $t("bills.invoice.remaining") }}
                </th>
                <th>
                  {{ selectedBill.price - selectedBill.paid }}
                  <span class="font-sans font-bold">{{
                    settings.currency
                  }}</span>
                </th>
              </tfoot>
            </table>
          </div>
          <div class="col-span-2 col-start-6 mt-12">
            <div>{{ $t("bills.invoice.issued_by") }}:</div>
            <div>{{ user.data.first_name + " " + user.data.last_name }}</div>
            <div>
              {{ formatDate(selectedBill.created_at) }}
            </div>
            <img
              v-if="user.data.signature"
              :src="server_url + user.data.signature"
              class="h-16 mt-1"
            />
          </div>
          <div
            v-if="selectedBill.notes"
            class="col-span-8 flex items-start flex-col"
          >
            <div>{{ $t("bills.notes") }}:</div>
            <div>{{ selectedBill.notes }}</div>
          </div>
          <div class="col-span-8 w-full border-b border-black mt-12"></div>
          <div style="direction: ltr;" class="col-span-3 flex justify-start">
            {{ settings.phone_number }}
          </div>
          <div class="col-span-2 text-center">{{ settings.network_name }}</div>
          <div class="col-span-3 flex justify-end">
            {{ settings.status_url }}
          </div>
        </div>
      </div>
      <div v-if="report" id="billprint" class="bg-white noScreen">
        <div class="w-full p-6" style="direction: rtl">
          <div class="flex gap-2">
            <p>{{ settings.network_name }}</p>
          </div>
          <div class="flex gap-2">
            <p>مشروع</p>
            <p>ADSL</p>
          </div>
          <div class="flex gap-2">
            <p>الشهر:</p>
            <p>{{ reportDateStart + " - " + reportDateEnd }}</p>
          </div>
          <div class="w-full border-b my-1 border-black"></div>
          <div
            v-for="(item, index) in report"
            :key="index"
            class="w-full grid grid-cols-3 mt-4"
          >
            <div class="flex gap-2">
              <p>مبلغ:</p>
              <p>{{ item.price }}</p>
            </div>
            <div class="flex gap-2">
              <p>عدد فواتير الشهر:</p>
              <p>{{ item.count }}</p>
            </div>
            <div class="flex gap-2">
              <p>مجموع فواتير الشهر:</p>
              <p>{{ item.paid }}</p>
            </div>
          </div>

          <div class="flex gap-2 font-bold mt-12">
            <p>مجموع فواتير الشهر:</p>
            <p>
              {{ report.sum }}
              <small class="font-bold">{{ settings.currency }}</small>
            </p>
          </div>
          <div class="flex gap-2 font-bold">
            <p>عدد البوابات المسددة:</p>
            <p>{{ report.count }}</p>
          </div>
          <div class="w-full border-b my-1 border-black"></div>
          <div class="mt-4 flex justify-between">
            <div>المشرف</div>
            <div>امين الكوة</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaginationRow from "../components/PaginationRow.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import axios from "axios";
import moment from "moment";
import BillPay from "../components/modals/BillPay.vue";
import { hasPrivilege } from "../global_constants.js";
import { mapGetters } from "vuex";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import VueDatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "Bills",
  components: {
    PaginationRow,
    LoadingOverlay,
    BillPay,
    WarnningConfirm,
    VueDatepicker,
  },
  data() {
    return {
      dataList: null,
      admins: null,
      meta: { last_page: 0 },
      loading: true,
      showFilters: false,
      showPayBill: false,
      showDeleteBill: false,
      selectedBill: null,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        id: "",
        slug: "",
        account_name: "",
        account_username: "",
        user_id: -1,
        profile_name: "",
        paid: "",
        from: "",
        to: "",
        notes: "",
        created_at: "",
        updated_at: "",
        save: false,
      },
      pdfOptions: {
        margin: 5,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 1, dpi: 96, letterRendering: true },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      format: (date) => {
        const day1 = date[0].getDate();
        const month1 = date[0].getMonth() + 1;
        const year1 = date[0].getFullYear();
        const hour1 = date[0].getHours();
        const minute1 = date[0].getMinutes();

        const day2 = date[1].getDate();
        const month2 = date[1].getMonth() + 1;
        const year2 = date[1].getFullYear();

        return `${day1}-${month1}-${year1} -> ${day2}-${month2}-${year2}`;
      },
      exportFilename: "accounts.pdf",
      total_bills: 0,
      total_paid: 0,
      current_bills: 0,
      current_paid: 0,
      autoUpdate: false,
      selection_array: [],
      debounce: null,
      loading_overlay: false,
      report_loading: false,
      reportReady: false,
      report: null,
      server_url: localStorage.getItem("server"),
      reportDateStart: "",
      reportDateEnd: "",
      showNotificationConfirm: false,
      notificationMessage: "",
    };
  },
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  async created() {
    this.prepareFilters();
    if (this.$route.query.username_debt) {
      this.filters.account_username = this.$route.query.username_debt;
      this.filters.paid = "0";
    }
    if (this.$route.query.username) {
      this.filters.account_username = this.$route.query.username;
    }
    this.applyFilters();
    this.fetchTotal();
    this.fetchAdmins();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    prepareFilters() {
      var filters = localStorage.getItem("bills_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    async applyFilters() {
      console.log(this.filters.updated_at);
      this.loading = true;
      var requestString = "bills?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.user_id != -1)
        requestString += `&user_id=${this.filters.user_id}`;
      if (this.filters.id != "") requestString += `&id=${this.filters.id}`;
      if (this.filters.account_username != "")
        requestString += `&account_username=${this.filters.account_username}`;
      if (this.filters.account_name != "")
        requestString += `&account_name=${this.filters.account_name}`;
      if (this.filters.slug != "")
        requestString += `&slug=${this.filters.slug}`;
      if (this.filters.profile_name != "")
        requestString += `&profile_name=${this.filters.profile_name}`;
      if (this.filters.paid != "")
        requestString += `&paid=${this.filters.paid}`;
      if (this.filters.from != "")
        requestString += `&from=${this.filters.from}`;
      if (this.filters.to != "") requestString += `&to=${this.filters.to}`;
      if (this.filters.notes != "")
        requestString += `&notes=${this.filters.notes}`;
      if (this.filters.created_at != "") {
        this.filters.created_at = [
          moment(this.filters.created_at[0]).format("yyyy-MM-DD"),
          moment(this.filters.created_at[1]).format("yyyy-MM-DD"),
        ];
        requestString += `&created_at=${this.filters.created_at}`;
      }
      if (this.filters.updated_at != "") {
        this.filters.updated_at = [
          moment(this.filters.updated_at[0]).format("yyyy-MM-DD"),
          moment(this.filters.updated_at[1]).format("yyyy-MM-DD"),
        ];
        requestString += `&updated_at=${this.filters.updated_at}`;
      }
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.filters.save)
          localStorage.setItem("bills_filters", JSON.stringify(this.filters));
        this.current_bills = 0;
        this.current_paid = 0;
        this.dataList.forEach((bill) => {
          this.current_bills += bill.price;
          this.current_paid += bill.paid;
        });
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchTotal() {
      const response = await axios.get("bills/total");
      if (response.status == 200) {
        this.total_bills = response.data.data.total_bills;
        this.total_paid = response.data.data.total_paid;
      }
    },
    async fetchAdmins() {
      const response = await axios.get("adminslog/admins");
      if (response.status == 200) {
        this.admins = response.data.data;
      }
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD hh:mm a");
    },
    async deleteBill() {
      const response = await axios.delete(`bills/${this.selectedBill.id}`);
      if (response.status == 200) {
        this.applyFilters();
        this.showDeleteBill = false;
      }
      this.selectedBill = null;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    isValidDate() {
      var dateStringS = this.reportDateStart;
      var dateStringE = this.reportDateEnd;
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      const result =
        dateStringS.match(regEx) != null && dateStringE.match(regEx) != null;
      if (result) {
        this.fetchReport();
      }
    },
    async fetchReport() {
      this.report_loading = true;
      const response = await axios.get(
        `bills/report?report_date_start=${this.reportDateStart}&report_date_end=${this.reportDateEnd}`
      );
      if (response.status == 200) {
        this.report = response.data.data;
        this.report.sum = response.data.sum;
        this.report.count = response.data.count;
        this.reportReady = true;
      }
      this.report_loading = false;
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("bills_filters", JSON.stringify(this.filters));
    },
    showBillNotification(bill) {
      if (!bill.account) return;
      this.notificationMessage = "عزيزي المشترك ";
      this.notificationMessage += `هذا الاشعار لتذكيرك بتسديد الفاتورة ذات الرمز: `;
      this.notificationMessage += `${bill.slug}\n`;
      this.notificationMessage += ` من تاريخ: `;
      this.notificationMessage += `${bill.created_at}\n`;
      this.notificationMessage += `وذلك لقاء اشتراك الانترنت `;
      this.notificationMessage += `${bill.profile.name} `;
      this.notificationMessage += `في شبكة `;
      this.notificationMessage += this.settings.network_name + "\n";
      this.notificationMessage += `المطلوب: `;
      this.notificationMessage += `${bill.price}`;
      this.notificationMessage += `${this.settings.currency}\n`;
      this.notificationMessage += `المدفوع: `;
      this.notificationMessage += `${bill.paid}`;
      this.notificationMessage += `${this.settings.currency}\n`;
      this.notificationMessage += `المتبقي: `;
      var remain = bill.price - bill.paid;
      this.notificationMessage += `${remain}`;
      this.notificationMessage += `${this.settings.currency}\n`;
      this.notificationMessage += `مجموع الديون الاجمالي: `;
      this.notificationMessage += `{مجموع_الديون}\n`;
      this.notificationMessage += `بادر بتسديد الفاتورة لضمان استمرارية الخدمة شاكرين تفهمكم\n`;
      this.notificationMessage += `\n`;
      this.notificationMessage += `الخدمة مقدمة من Super Speed Radius`;

      this.showNotificationConfirm = true;
    },
    async sendNotification() {
      this.showNotificationConfirm = false;
      this.loading = true;
      const response = await axios.post(
        `bills/${this.selectedBill.id}/notification`
      );
      if (response.status == 200) {
        this.selectedBill = null;
        this.loading = false;
        this.$router.push({
          query: { success: "success" },
        });
      }
    },
    print_element(element_id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(element_id).innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <link href="/js/about.js" rel="prefetch">
          <link href="/styles/app.scss" rel="stylesheet">
          <link href="/styles/main.css" rel="stylesheet">
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
  },
};
</script>
<style scoped>
@media screen {
  .noprint {
  }
  .noScreen {
    display: none;
  }
  .scroll {
    height: 30rem;
  }
}

@media print {
  .noprint {
    display: none;
  }
  .noScreen {
  }
  .scroll {
  }
}
</style>
